<template>
  <page-list>
    <template #header>
      <el-form ref="form" :model="form" :rules="rules" label-width="120px" inline=true>
        <el-form-item label="所属门店：" prop="shopId">
          <el-select
            v-model="form.shopId"
            placeholder="请选择"
            clearable
            filterable
          >
            <el-option
              v-for="(item, index) in shopOptions"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="姓名：" prop="userName">
          <el-input
            v-model="form.userName"
            placeholder="请输入"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="联系电话：" prop="mobile">
          <el-input
            v-model="form.mobile"
            placeholder="请输入"
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList()">查 询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="
          editMode = 'add';
          dialogVisible = true;
        ">添 加</el-button>
        </el-form-item>
      </el-form>
    </template>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      stripe
      v-loading="loading"
      element-loading-background="transparent"
      @sort-change="sortChangeHandle"
      @row-dblclick="editHandle"
    >
      <el-table-column
        prop="id"
        label="ID"
        min-width="40px"
        header-align="center"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="shopId"
        label="店铺"
        header-align="center"
        align="center"
        min-width="120"
        show-overflow-tooltip
      >
      <template v-slot="scope">
        <span v-if="scope.row.shopList[0]">{{scope.row.shopList[0].name}}</span>
        <span v-else>{{shopFormatter(scope.row.shopId)}}</span>
      </template>
      </el-table-column>
      <el-table-column
        prop="username"
        label="用户名"
        header-align="center"
        align="center"
        min-width="120px"
        show-overflow-tooltip
      >
      </el-table-column>
      <!-- <el-table-column
        prop="password"
        label="密码"
        header-align="center"
        align="center"
        min-width="250px"
      >
      </el-table-column> -->
      <el-table-column
        prop="sex"
        label="性别"
        header-align="center"
        align="center"
        min-width="80"
      >
      </el-table-column>
      <el-table-column
        prop="idCard"
        label="身份证号"
        header-align="center"
        align="center"
        min-width="200"
      >
      </el-table-column>
      <el-table-column
        prop="mobile"
        label="联系电话"
        header-align="center"
        align="center"
        min-width="120"
      >
      </el-table-column>
      <el-table-column
        prop="status"
        label="状态"
        header-align="center"
        align="center"
        min-width="70px"
      >
        <template v-slot="scope">
          <span
            class="fa fa-circle"
            :style="{ color: EMPLOYEE_STATUS[scope.row.status].color }"
            >{{ " " + EMPLOYEE_STATUS[scope.row.status].label }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="logintime"
        label="最后登录时间"
        header-align="center"
        align="center"
        min-width="150px"
        :formatter="dateFormatter"
        sortable="custom"
      >
      </el-table-column>
      <el-table-column label="操作" header-align="center" align="center" fixed="right" min-width="120px">
        <template v-slot="scope">
          <el-tooltip content="查看点单记录" placement="top">
            <el-button class="icon-btn fa fa-eye" type="primary" @click="viewHandle(scope.row)" />
          </el-tooltip>
          <el-tooltip content="编辑" placement="top">
            <el-button class="icon-btn fa fa-pencil" type="primary" @click="editHandle(scope.row)" />
          </el-tooltip>
          <el-tooltip content="删除" placement="top">
            <el-button class="icon-btn fa fa-trash" type="danger" @click="delHandle(scope.row)" />
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-model="paginationData"
      :total="total"
      @change="getList"
    ></pagination>

    <EditDialog
      v-model="dialogVisible"
      @refresh="getList"
      :editData="editData"
      :mode="editMode"
    ></EditDialog>

    <DetailDialog
      v-model="detailVisible"
      @refresh="getList"
      :detailData="detailData"
      :mode="editMode"
    ></DetailDialog>
  </page-list>
</template>

<script>
import { EMPLOYEE_STATUS } from "@/helpers/constants";
import EditDialog from "./editDialog";
import DetailDialog from "./detailDialog";
import { listByPage, deleteById, listSummarySaleData } from "@/apis/unidrink/employee";
import { getShopOptions } from "@/apis/auth";
import { cloneDeep } from "lodash";
import { msgErr, msgSuc } from "@/helpers/message";

export default {
  components: { EditDialog,DetailDialog },
  data() {
    return {
      EMPLOYEE_STATUS: EMPLOYEE_STATUS,
      tableData: [],
      paginationData: {},
      initForm: {
        sort: "id",
        order: "desc",
      },
      form: {},
      total: 0,
      loading: false,
      dialogVisible: false,
      editMode: "add",
      editData: {},
      detailVisible:false,
      detailData:{},
      shopOptions:[],
    };
  },
  created() {},
  mounted() {
    getShopOptions(this.shopOptions);
  },
  activated() {
    this.form = cloneDeep(this.initForm);
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      listByPage({ ...this.form, ...this.paginationData })
        .then(({ code, result, message }) => {
          if (code !== "0000") {
            msgErr(message);
            return;
          }
          const { list, total } = result;
          this.tableData = list;
          this.total = total;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    dateFormatter(row) {
      return row.logintime
        ? this.$moment.unix(row.logintime).format("yyyy-MM-DD HH:mm:ss")
        : "无";
    },

    sortChangeHandle({ prop, order }) {
      if (prop && order) {
        this.form = {
          sort: prop,
          order: order === "descending" ? "desc" : "asc",
        };
      } else {
        this.form = cloneDeep(this.initForm);
      }
      this.getList();
    },

    delHandle(row) {
      this.$confirm("确定删除此项？", "温馨提示").then(() => {
        deleteById(row.id).then(({ code, message }) => {
          if (code === "0000") {
            msgSuc("操作成功！");
            this.getList();
          } else {
            msgErr(message);
          }
        });
      });
    },

    viewHandle(row){
      this.detailVisible = true;
      this.detailData = {id : row.id};
    },

    editHandle(row) {
      this.editMode = "edit";
      this.dialogVisible = true;
      this.editData = row;
    },

    shopFormatter(cellValue){
      for(let i=0;i<this.shopOptions.length;i++){
        let shopInfo = this.shopOptions[i];
        if(shopInfo.id == cellValue){
          return shopInfo.name;
        }
      }
      return cellValue;
    },
  },
};
</script>

<style lang="scss" scoped></style>
