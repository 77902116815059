<template>
  <el-dialog title="详情" :model-value="modelValue" width="640px" @close="close" :close-on-click-modal="true">
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      stripe
      v-loading="loading"
      element-loading-background="transparent"
      @sort-change="sortChangeHandle"
      @row-dblclick="editHandle"
    >
      <el-table-column
        prop="date"
        label="日期"
        min-width="40px"
        header-align="center"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="count"
        label="数量"
        min-width="40px"
        header-align="center"
        align="center"
      >
      </el-table-column>
    </el-table>
    <pagination
      v-model="paginationData"
      :total="total"
      @change="getList"
    ></pagination>
    <template #footer>
      <div>
        <el-button type="primary" :loading="loading" @click="close">确 定</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { msgErr, msgSuc } from "@/helpers/message";
import { listSummarySaleData } from "@/apis/unidrink/employee";
import { cloneDeep } from "lodash";

export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    detailData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      paginationData:{},
      tableData:[],
      total:0,
      initForm: {
          id:"",
      },
      form: {},
      loading: false
    };
  },
  emits: ["update:modelValue"],
  created() {
  },
  mounted() {},
  activated() {},
  watch: {
    modelValue(val) {
      if (val) {
        this.resetHandle();
      }
    }
  },
  methods: {
    resetHandle() {
      this.form = cloneDeep(this.detailData);
      this.getList();
    },
    getList() {
      this.loading = true;
      listSummarySaleData({ ...this.form, ...this.paginationData })
        .then(({ code, result, message }) => {
          if (code !== "0000") {
            msgErr(message);
            return;
          }
          const { list, total } = result;
          this.tableData = list;
          this.total = total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.$emit("update:modelValue", false);
    }
  }
};
</script>

<style lang="scss" scoped>
.message-body {
  margin-bottom: 18px;
  line-height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  .message-label {
    text-align: right;
    display: flex;
    flex-direction: column;
    padding-right: 12px;
  }
  .message-value {
    text-align: left;
    display: flex;
    flex-direction: column;
    padding: 0 11px;
  }
}
.next-card{
  margin-top: 12px;
}
</style>
